import type { WRegistryCollectionItemViewResponse } from '@zola/svc-web-api-ts-client';

import { createAction } from '@reduxjs/toolkit';

import type { RegistryType } from '@/types/registry';

const REQUEST_REGISTRY_INFO_BY_SLUG = 'zola/registry/REQUEST_REGISTRY_INFO_BY_SLUG';
const RECEIVED_REGISTRY_INFO_BY_SLUG = 'zola/registry/RECEIVED_REGISTRY_INFO_BY_SLUG';
const REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID =
  'zola/registry/REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID';
const RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID =
  'zola/registry/RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID';
const REQUEST_REGISTRY_INFO_BY_COLLECTION_ITEM_ID =
  'zola/registry/REQUEST_REGISTRY_INFO_BY_COLLECTION_ITEM_ID';
const RECEIVED_REGISTRY_INFO_BY_COLLECTION_ITEM_ID =
  'zola/registry/RECEIVED_REGISTRY_INFO_BY_COLLECTION_ITEM_ID';
const REQUEST_COLLECTION_INFO_BY_REGISTRY_ID =
  'zola/registry/REQUEST_COLLECTION_INFO_BY_REGISTRY_ID';
const RECEIVED_COLLECTION_INFO_BY_REGISTRY_ID =
  'zola/registry/RECEIVED_COLLECTION_INFO_BY_REGISTRY_ID';
const REQUEST_REGISTRY_INFO_BY_ID = 'zola/registry/REQUEST_REGISTRY_INFO_BY_ID';
const RECEIVED_REGISTRY_INFO_BY_ID = 'zola/registry/RECEIVED_REGISTRY_INFO_BY_ID';

export const requestCollectionInfoByRegistryId = createAction(
  REQUEST_COLLECTION_INFO_BY_REGISTRY_ID
);

export const receivedCollectionInfoByRegistryId = createAction<unknown>(
  RECEIVED_COLLECTION_INFO_BY_REGISTRY_ID
);

export const requestRegistryBySlug = createAction(REQUEST_REGISTRY_INFO_BY_SLUG);

export const receivedRegistryBySlug = createAction<RegistryType>(RECEIVED_REGISTRY_INFO_BY_SLUG);

export const requestCollectionItemByCollectionId = createAction(
  REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID
);

export const receivedCollectionItemByCollectionId =
  createAction<WRegistryCollectionItemViewResponse>(RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID);

export const requestRegistryInfoByCollectionId = createAction(
  REQUEST_REGISTRY_INFO_BY_COLLECTION_ITEM_ID
);

export const receivedRegistryInfoByCollectionId = createAction<RegistryType>(
  RECEIVED_REGISTRY_INFO_BY_COLLECTION_ITEM_ID
);

export const requestRegistry = createAction(REQUEST_REGISTRY_INFO_BY_ID);

export const receiveRegistry = createAction<RegistryType>(RECEIVED_REGISTRY_INFO_BY_ID);
