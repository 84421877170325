import type { WProductView, WRetailerView } from '@zola/svc-web-api-ts-client';

import type { RegistryItemDetails, RegistryItemDetailsErrors } from '@/types/RegistryItemDetails';

import * as ActionTypes from '../actions/types/ThirdPartyTypes';

interface ThirdPartyState {
  giftForm: RegistryItemDetails | null;
  giftFormErrors: RegistryItemDetailsErrors;
  productRecommendations: WProductView[] | null;
  isSubmitting: boolean;
  bulkImport: {
    currentIndex: number;
    registryUrlError: boolean;
    selectedRetailerError: boolean;
    selectedRetailerDetails: WRetailerView | null;
    supportedRetailers: WRetailerView[];
  };
}

const initialState: ThirdPartyState = {
  giftForm: null,
  giftFormErrors: {},
  productRecommendations: null,
  isSubmitting: false,
  bulkImport: {
    currentIndex: 0,
    registryUrlError: false,
    selectedRetailerError: false,
    selectedRetailerDetails: null,
    supportedRetailers: [],
  },
};

const GIFT_FORM_DEFAULTS: RegistryItemDetails = {
  cashFund: false,
  enableMostWanted: false,
  groupGift: false,
  imageUrl: null,
  name: '',
  personalNote: '',
  priceCents: 0,
  productUrl: '',
  quantity: 1,
};

const thirdPartyReducer = (
  state = initialState,
  action: ActionTypes.ThirdPartyAction
): ThirdPartyState => {
  switch (action.type) {
    case ActionTypes.TOGGLE_SUBMITTING: {
      return { ...state, isSubmitting: !state.isSubmitting };
    }
    case ActionTypes.RECEIVED_SUPPORTED_RETAILERS: {
      return { ...state, bulkImport: { ...state.bulkImport, supportedRetailers: action.payload } };
    }
    case ActionTypes.INCREMENT_INDEX: {
      return {
        ...state,
        bulkImport: { ...state.bulkImport, currentIndex: state.bulkImport.currentIndex + 1 },
      };
    }
    case ActionTypes.SET_SELECTED_RETAILER: {
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          registryUrlError: false,
          selectedRetailerError: false,
          selectedRetailerDetails: action.payload,
        },
      };
    }
    case ActionTypes.THROW_REGISTRY_URL_ERROR: {
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          registryUrlError: true,
        },
      };
    }
    case ActionTypes.THROW_SELECTED_RETAILER_ERROR: {
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          selectedRetailerError: true,
          selectedRetailerDetails: null,
        },
      };
    }
    case ActionTypes.RESET_RETAILER: {
      return {
        ...state,
        bulkImport: {
          ...state.bulkImport,
          selectedRetailerDetails: null,
          selectedRetailerError: false,
          currentIndex: 1,
        },
      };
    }
    case ActionTypes.RESET_SELECTED_RETAILER_ERROR: {
      return { ...state, bulkImport: { ...state.bulkImport, selectedRetailerError: false } };
    }
    case ActionTypes.PREFILL_GIFT_FORM: {
      return {
        ...state,
        giftForm: {
          ...GIFT_FORM_DEFAULTS,
          ...action.payload,
        },
        giftFormErrors: {},
      };
    }
    case ActionTypes.UPDATE_GIFT_FORM: {
      return {
        ...state,
        giftForm: {
          ...state.giftForm,
          ...action.payload,
        },
      };
    }
    case ActionTypes.CLEAR_GIFT_FORM: {
      return {
        ...state,
        giftForm: null,
        giftFormErrors: {},
      };
    }
    case ActionTypes.SET_GIFT_FORM_ERRORS: {
      return {
        ...state,
        giftFormErrors: { ...action.payload },
      };
    }
    case ActionTypes.SET_PRODUCT_RECOMMENDATIONS: {
      // Exclude the "Add to cart" action from bookmarklet pages
      const products = action.payload.map((item) => {
        const updatedViews = item.product_look_views?.map((view) => {
          const updatedActions = view.tile_actions
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ?.filter((a) => a.key !== ('ADD_TO_CART' as any))
            .map((a) => ({ ...a, primary: true }));
          return {
            ...view,
            tile_actions: updatedActions,
          };
        });
        return {
          ...item,
          product_look_views: updatedViews,
        };
      });
      return {
        ...state,
        productRecommendations: products,
      };
    }
    default:
      return state;
  }
};

export default thirdPartyReducer;
