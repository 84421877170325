import type { WModuleGroupView } from '@zola/svc-web-api-ts-client';

import type { AnyAction } from 'redux';

import { requestModuleGroup, receiveModuleGroup } from '@/actions/types/ModuleGroupActionTypes';

interface ModuleGroupState {
  busy: boolean;
  byTag: {
    [key: string]: WModuleGroupView;
  };
}

const initialState: ModuleGroupState = {
  busy: true,
  byTag: {},
};

const moduleGroupReducer = (state = initialState, action: AnyAction): ModuleGroupState => {
  if (requestModuleGroup.match(action)) {
    return { ...state, busy: true };
  }
  if (receiveModuleGroup.match(action)) {
    const { tag, data } = action.payload;
    return {
      ...state,
      busy: false,
      byTag: {
        ...state.byTag,
        [tag]: data,
      },
    };
  }
  return state;
};

export default moduleGroupReducer;
