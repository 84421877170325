import type { WProductView, WRetailerView } from '@zola/svc-web-api-ts-client';

import { RegistryItemDetails, RegistryItemDetailsErrors } from '@/types/RegistryItemDetails';

export const REDIRECT = 'zola/thirdParty/REDIRECT';
export const TOGGLE_SUBMITTING = 'zola/thirdParty/TOGGLE_SUBMITTING';
export const RECEIVED_SUPPORTED_RETAILERS = 'zola/thirdParty/RECEIVED_SUPPORTED_RETAILERS';
export const INCREMENT_INDEX = 'zola/thirdParty/INCREMENT_INDEX';
export const SET_SELECTED_RETAILER = 'zola/thirdParty/SET_SELECTED_RETAILER';
export const THROW_REGISTRY_URL_ERROR = 'zola/thirdParty/THROW_REGISTRY_URL_ERROR';
export const THROW_SELECTED_RETAILER_ERROR = 'zola/thirdParty/THROW_SELECTED_RETAILER_ERROR';
export const RESET_RETAILER = 'zola/thirdParty/RESET_RETAILER';
export const RESET_SELECTED_RETAILER_ERROR = 'zola/thirdParty/RESET_SELECTED_RETAILER_ERROR';
export const PREFILL_GIFT_FORM = 'zola/thirdParty/PREFILL_GIFT_FORM';
export const UPDATE_GIFT_FORM = 'zola/thirdParty/UPDATE_GIFT_FORM';
export const CLEAR_GIFT_FORM = 'zola/thirdParty/CLEAR_GIFT_FORM';
export const SET_GIFT_FORM_ERRORS = 'zola/thirdParty/SET_GIFT_FORM_ERRORS';
export const SET_PRODUCT_RECOMMENDATIONS = 'zola/thirdParty/SET_PRODUCT_RECOMMENDATIONS';

type RedirectAction = {
  type: typeof REDIRECT;
};

type ToggleSubmittingAction = {
  type: typeof TOGGLE_SUBMITTING;
};

type ReceivedSupportedRetailersAction = {
  type: typeof RECEIVED_SUPPORTED_RETAILERS;
  payload: WRetailerView[];
};

type IncrementImportFlowIndexAction = {
  type: typeof INCREMENT_INDEX;
};

type SetSelectedRetailerAction = {
  type: typeof SET_SELECTED_RETAILER;
  payload: WRetailerView;
};

type ThrowRegistryUrlAction = {
  type: typeof THROW_REGISTRY_URL_ERROR;
};

type ThrowSelectedRetailerAction = {
  type: typeof THROW_SELECTED_RETAILER_ERROR;
};

type ResetRetailerAction = {
  type: typeof RESET_RETAILER;
};

type ResetSelectedRetailerErrorAction = {
  type: typeof RESET_SELECTED_RETAILER_ERROR;
};

type PrefillGiftFormAction = {
  type: typeof PREFILL_GIFT_FORM;
  payload: Partial<RegistryItemDetails>;
};

type UpdateGiftFormAction = {
  type: typeof UPDATE_GIFT_FORM;
  payload: Partial<RegistryItemDetails>;
};

type ClearGiftFormAction = {
  type: typeof CLEAR_GIFT_FORM;
};

type SetGiftFormErrorsAction = {
  type: typeof SET_GIFT_FORM_ERRORS;
  payload: RegistryItemDetailsErrors;
};

type SetProductRecommendationsAction = {
  type: typeof SET_PRODUCT_RECOMMENDATIONS;
  payload: WProductView[];
};

export type ThirdPartyAction =
  | RedirectAction
  | ToggleSubmittingAction
  | ReceivedSupportedRetailersAction
  | IncrementImportFlowIndexAction
  | SetSelectedRetailerAction
  | ThrowRegistryUrlAction
  | ThrowSelectedRetailerAction
  | ResetRetailerAction
  | ResetSelectedRetailerErrorAction
  | PrefillGiftFormAction
  | UpdateGiftFormAction
  | ClearGiftFormAction
  | SetGiftFormErrorsAction
  | SetProductRecommendationsAction;
